<template>
  <section>
     <infantil/>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import infantil from "@/components/educacao-infantil/educacao-infantil.vue"

export default defineComponent({
  components:{
    infantil
  },
  data() {
    return {
    };
  },
});
</script>
