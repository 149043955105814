import axios from 'axios'

export class Creche {
  static async cadastrarRespostas(array) {
    const ano = sessionStorage.getItem("anoSelecionado");
    return axios.post('creche/respostas/todos', {
      array: array,
      ano: Number(ano)
    })
  }
  static async respostasCrecheShowAll(perguntas_creche_id) {
    return axios.get('creche/resp/' + perguntas_creche_id);
  }
  static async respostasCrecheShowAllAluno(perguntas_creche_id, aluno_id) {
    return axios.get('creche/resp/' + perguntas_creche_id + '/' + aluno_id);
  }

  static async respostasCrecheShowAllAlunoPeriodo(periodo, aluno_id, tipoPergunta) {
    const ano = sessionStorage.getItem("anoSelecionado");

    return axios.get('creche/getresp/' + periodo + '/' + aluno_id + '/' + ano + '/' + tipoPergunta)
  }
  static async updateRespostasCrecheAlunoPeriodo(array) {
    return axios.put('creche/updateresp', {
      array: array,
    });
  }
}
